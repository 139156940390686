import { render, staticRenderFns } from "./PersonalVerification.vue?vue&type=template&id=6a18ee65&scoped=true"
import script from "./PersonalVerification.vue?vue&type=script&lang=js"
export * from "./PersonalVerification.vue?vue&type=script&lang=js"
import style0 from "./PersonalVerification.vue?vue&type=style&index=0&id=6a18ee65&prod&lang=css"
import style1 from "../assets/cuprum.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "fulfillment-cuprum-auth-widget/dist/fulfillment-cuprum-auth-widget.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "../assets/style-personal-verification.css?vue&type=style&index=3&id=6a18ee65&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a18ee65",
  null
  
)

export default component.exports